import React from "react";
import { BrowserRouter } from "react-router-dom";
import {Routes} from './routing/Routes'
import history from "./utils/history";

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

import './App.css';

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyD45G-bU9KRSUK81ODY_q6sfxAu4N-qLiU",
    authDomain: "ait-atlas-64345.firebaseapp.com",
    projectId: "ait-atlas-64345",
    storageBucket: "ait-atlas-64345.appspot.com",
    messagingSenderId: "203600627871",
    appId: "1:203600627871:web:9b062457b2b70fae90faaa",
    measurementId: "G-F86SEPF8PG"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <div className="App">
      <BrowserRouter >
        <Routes/>
      </BrowserRouter>
    </div>
  );
}

export default App;