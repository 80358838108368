//Declare Dependencies
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session' // defaults to localStorage for web and AsyncStorage for react-native
import { createStore, applyMiddleware } from 'redux';

// Set Reducers
import rootReducer from './rootReducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ["auth"],
    blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const middleware = [];
middleware.push(thunk.withExtraArgument());
const store = createStore(
    persistedReducer,
    applyMiddleware(
        ...middleware,
        createLogger()
    ),
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export {
    store,
    persistor,
};