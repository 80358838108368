import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export const login = async (username, password, dispatch) =>  {

    try {
        let data = {
            identifier: username,
            password: password
        }
    
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
       let response  = await axios.post(`${API_URL}/auth/local/`, data, config);
       let user = response.data.user
       let access_token = response.data.jwt
       
       dispatch({ type: 'SET_USER', payload: user})
       dispatch({ type: 'SET_ACCESS_TOKEN', payload: access_token})
       return {};
    } catch (error) {
        return error.message
    }


};
