import React, { useState, useEffect } from 'react'
import { CircularProgress, Container, Snackbar } from "@mui/material";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { login } from './actions'
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Auth = () => {
    const dispatch = useDispatch();

    const [is_loadding, setIsLoading] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [severitySnack, setSeveritySnack] = useState('warning');
    const [messageSnack, setMessageSnack] = useState('');
    const [openSnack, setOpenSnack] = useState(false);

    const handleCloseSnack = (event, reason) => {    
        setOpenSnack(false);
    };

    useEffect(() => {
        (async () => {
            setUsername("")
            setPassword("")
        })()
    }, [])

    const submitForm = async () => {
        if (!username) {
            setError('Please enter a username')
            return
        } else if (!password) {
            setError('Please enter a password')
            return
        } else {
          setIsLoading(true)
          let response = await login(username, password, dispatch);
          if(response == 'Request failed with status code 400') {
            setIsLoading(false)
            setMessageSnack('Incorrect Username or Password provided')
            setOpenSnack(true)
          }
        }
    }

    return (
        <>
        <h1 className='welcome_text'>WELCOME TO DIGITAL ATLAS</h1>
        <div className="spacer_large"></div>

        <Container maxWidth="sm">
            <div className="full_width">

                <div className="login_wrapper">
                    <div id="password_prompt" className="rounded login_box">
                        <p className='login_label'>Username</p>
                        <input type="text" value={username} size="20" placeholder="Username" onChange={e => (setUsername(e.target.value), setError(""))} />
                        <p className='login_label'>Password</p>
                        <input value={password} onChange={e => (setPassword(e.target.value), setError(""))}  id="password" type="password" size="20" placeholder="Password"/>
                        
                        {!is_loadding ? <button id="sign_in_button" type="button" className="button" onClick={(submitForm)}>Sign in</button> : <button className="button" disabled={true}><CircularProgress color="inherit" /></button>}
                        <div style={{paddingTop: "20px"}}>
                            <i className='login_box_i'>The privileged password can be obtained from <a className='login_box_a' href="mailto:mercedes.romano@alk.net">mercedes.romano@alk.net</a></i>
                        </div>
                    </div>
                </div>
                

                <div className="login_info_wrapper">
                    <div className='login_info_box'>
                        <b>Allergy solutions for life</b>
                        <br/>
                        <p>More than 500 million people worldwide have allergies. Many of them suffer in silence because the way ahead is too confusing.</p>
                        <br/>
                        <p>By collecting information and the latest and most trusted solutions together in one place, we want to make allergy surprisingly simple to manage. With 100 years of experience, nobody knows allergy like us, and we continuously apply our scientific knowledge and expertise to help people take control of their allergy and their life.</p>
                        <br/>
                        <p>We want to make a difference by offering solutions for everyone who is touched by allergy - through a comprehensive range of products, services and resources that offer a fast-track to a more balanced life.</p>
                    </div>
                </div>

                <div className="login_info_wrapper_bottom">
                    <div className='login_info_box_bottom'>
                        <b>The AIT Atlas data is limited to ALK’s database</b>
                    </div>
                </div>

            </div>
        </Container>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnack} severity={severitySnack} sx={{ width: '100%' }}>
                {messageSnack}
            </Alert>
        </Snackbar>
        </>
    );
}

export default Auth;




