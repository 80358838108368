import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../views/fallback-view/FallbackView'

export function PrivateRoutes() {
  const ComparatorPage = lazy(() => import('../views/comparator/ComparatorPage'))
  const AdminPage = lazy(() => import('../views/admin/AdminPage'))
  

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/comparator' component={ComparatorPage} />
        <Route path='/admin' component={AdminPage} />
        <Redirect exact from='/' to='/comparator' />
        <Redirect exact from='/auth' to='/comparator' />
      </Switch>
    </Suspense>
  )
}
